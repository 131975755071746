import 'react-table/react-table.css';
import 'moment/locale/es';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';

import {
  Button,
  IconButton,
  Paper,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Close from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';

import { paymentsHardcoded } from 'app/config/paymentsHardcoded';
import {
  accountingRoles,
  adminRoles,
  superAdminRoles,
} from '../../../../config/roles';

import {
  formatNumber,
  roundValue,
  formatSingleDate,
  getColumnWidth,
  getNested,
  formatDate,
} from '../../../../utils';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import MUIAutocompleteMultipleAgencies from 'app/components/MUIAutocompleteMultipleAgencies';
import { MenuOption } from 'app/components/MenuOption';

import {
  selectAgencies,
  makeSelectGetOneAgency,
} from 'app/features/agencies/selectors';
import {
  makeSelectUserRoleFromState,
  selectAuth,
} from '../../../auth/selectors';

import {
  getPayments,
  getPaymentsOrders,
  getTPVPayments,
  getTPVFiservPayments,
  getPaymentsForDownload,
  getPaymentsTPVForDownload,
  getPaymentsCompleteForDownload,
} from 'app/features/payments/paymentsSlice';

import { getOneAgency } from 'app/features/agencies/agenciesSlice';

import Modal from '../../../../components/Modal';
import { Badge } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    widht: '100%',
    marginBottom: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1),
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  multiselect: {
    minWidth: '200px',
    width: 'auto',
    maxWidth: '420px',
  },
  datePickerRight: {
    marginRight: theme.spacing(3),
  },
  range: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '70px',
    },
    ['@media screen and (max-width: 1024px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '90px',
    },
    width: '90px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1630px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    ['@media screen and (min-width: 1630px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  minWidth: {
    ['@media screen and (min-width: 600px)']: {
      // eslint-disable-line no-useless-computed-key
      minWidth: '110px',
    },
  },
  formControl: {
    marginTop: '8px',
    marginRight: '5px',
    backgroundColor: '#FFF',
  },
}));

const order = {
  0: 'serviceOrder',
  1: 'paymentOrder',
  2: 'paymentOrder',
};

function PaymentsTable(props) {
  const classes = useStyles();
  const {
    getPayments,
    getPaymentOrders,
    getTPVPayments,
    getTPVFiservPayments,
    role,
    paymentsTPV,
    paymentsTPVFiserv,
    intl,
    getPaymentsForDownload,
    getPaymentsTPVForDownload,
    user,
    userAgency,
    getOneAgency,
    getPaymentsCompleteForDownload,
  } = props;

  const reactTable = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [payments, setPayments] = useState([]);
  const [tpvPayments, setTPVPayments] = useState([]);
  const [tpvFiservPayments, setTPVFiservPayments] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [filtered, setFiltered] = useState('');
  const [csvReport, setCsvReport] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [csvReportComplete, setCsvReportComplete] = useState([]);
  const [dataToExportComplete, setDataToExportComplete] = useState([]);
  const [userAgencyData, setUserAgencyData] = useState('');
  const [agency, setAgency] = useState('');
  const [agenciesList, setAgenciesList] = useState([]);
  const [agenciesName, setAgenciesName] = useState([]);

  const paymentsToBring = [
    getPayments,
    getPaymentOrders,
    getPaymentOrders,
    getPaymentOrders,
    getPaymentOrders,
  ];
  const paymentsSelector = [
    props.payments.fromdb,
    props.payments.paymentsOrdersFromdb,
    props.payments.paymentsOrdersFromdb,
    props.payments.paymentsOrdersFromdb,
  ];
  const orders = paymentsSelector[tabSelected];
  const userInfo = {
    role,
    userName: user,
  };

  const getStatus = (status) => {
    switch (status.toUpperCase()) {
      case 'DONE':
        return 'Pagado';
      case 'RETURNED':
        return 'Devuelto';
      case 'REFUND':
      case 'REFUN':
        return 'Reembolso';
      case 'overdue':
        return 'Cobrable';
    }
  };

  useEffect(() => {
    setUserAgencyData(userAgency);
  }, [userAgency]);

  useEffect(() => {
    if (user?.user?.agencyID) {
      const data = user?.user?.agencyID;
      getOneAgency({ data });
    }
  }, [user]);

  useEffect(() => {
    let paymentsToCSV = [];
    let paymentsForDownload = props.payments.paymentsForDownload;
    if (
      paymentsForDownload &&
      paymentsForDownload.Detalle &&
      paymentsForDownload.Detalle.length > 0
    ) {
      setDataToExport(paymentsForDownload);
    }
  }, [props.payments.paymentsForDownload]);

  useEffect(() => {
    let paymentsToCSV = [];
    let paymentsTPVFiservForDownload =
      props.payments.paymentsTPVFiservForDownload;
    if (
      Array.isArray(paymentsTPVFiservForDownload) &&
      paymentsTPVFiservForDownload.length > 0
    ) {
      paymentsToCSV = paymentsTPVFiservForDownload.map((payment) => {
        const total = payment['TOTAL']
          ? formatNumber(payment['TOTAL'])
          : '$0.00';
        const paymentDate = payment['FECHA DE TRANSACCIÓN']
          ? formatDate(payment['FECHA DE TRANSACCIÓN'])
          : '-';
        const depositDate = payment['FECHA DEPOSITO']
          ? formatDate(payment['FECHA DEPOSITO'])
          : '-';
        return {
          ID: getNested('-', payment, 'ID'),
          AGENCIA: getNested('-', payment, 'AGENCIA'),
          'LOCALIZACIÓN ID': getNested(
            '-',
            payment,
            'LOCALIZACIÓN ID',
          ),
          'ID TERMINAL': getNested('-', payment, 'ID TERMINAL'),
          FACTURA: getNested('-', payment, 'FACTURA'),
          TARJETA: getNested('-', payment, 'TARJETA'),
          BANCO: getNested('-', payment, 'BANCO'),
          TOTAL: total,
          DESCRIPCIÓN: getNested('-', payment, 'DESCRIPCIÓN'),
          'FECHA DE TRANSACCIÓN': paymentDate ? paymentDate : '-',
          'TIEMPO TRANSACCIÓN': getNested(
            '-',
            payment,
            'TIEMPO TRANSACCIÓN',
          ),
          'CÓDIGO AUTORIZACIÓN': getNested(
            '-',
            payment,
            'CÓDIGO AUTORIZACIÓN',
          ),
          'FECHA DE DEPOSITO': depositDate,
        };
      });
    }
    setCsvReport(paymentsToCSV);
  }, [props.payments.paymentsTPVFiservForDownload]);

  useEffect(() => {
    let paymentsToCSV = [];
    let paymentsForDownload =
      props.payments.paymentsCompleteForDownload;
    if (
      (paymentsForDownload && 
        paymentsForDownload.DetalleODS &&
        paymentsForDownload.DetalleODS.length > 0) ||
      (paymentsForDownload && 
        paymentsForDownload.DetalleODP &&
        paymentsForDownload.DetalleODP.length > 0)
    ) {
      setDataToExportComplete(paymentsForDownload);
    }
  }, [props.payments.paymentsCompleteForDownload]);

  useEffect(() => {
    if (firstDate && secondDate && secondDate >= firstDate) {
      const data = {
        fromDate: moment(firstDate).format('YYYY-MM-DD'),
        toDate: moment(secondDate).format('YYYY-MM-DD'),
        agenciesList,
        agencyID:
          user?.user?.role !== 'Super Admin'
            ? user?.user?.agencyID
            : null,
        wheres: '',
      };
      const info = {
        data,
        wheres: filtered,
        type: tabSelected === 0 ? 'ods' : 'odp',
      };
      if (tabSelected === 4) {
        getPaymentsTPVForDownload(info);
      } else {
        getPaymentsForDownload(info);
      }
    }
  }, [firstDate, secondDate, tabSelected, filtered, agenciesList]);

  useEffect(() => {
    if (firstDate && secondDate && secondDate >= firstDate) {
      const data = {
        fromDate: moment(firstDate).format('YYYY-MM-DD'),
        toDate: moment(secondDate).format('YYYY-MM-DD'),
        agencyID:
          user?.user?.role !== 'Super Admin'
            ? user?.user?.agencyID
            : null,
        wheres: '',
        agenciesList,
        wheresOBJ: {},
      };
      const info = {
        data,
        wheres: filtered,
      };
      getPaymentsCompleteForDownload(info);
    }
  }, [firstDate, secondDate, filtered, agenciesList]);

  useEffect(() => {
    props.setLeftSideComponent(
      <div>
        <FormControl
          variant="outlined"
          size="small"
          lassName={classes.formControl}
        >
          <InputLabel htmlFor="name-native-disabled">
            Selecciona tipo de pago
          </InputLabel>
          <Select
            value={tabSelected}
            onChange={handleChangeTab}
            label="Age"
          >
            <MenuItem value={0}>Órdenes de servicio</MenuItem>
            <MenuItem value={1}>Órdenes de pago</MenuItem>
            <MenuItem value={2}>Anticipos Refacciones</MenuItem>
            <MenuItem value={3}>TPV</MenuItem>
            {user?.user?.role === 'Service Manager' ||
              user?.user?.role === 'Super Admin' ||
              user?.user?.role === 'Agency Manager' ||
              user?.user?.role === 'Super Admin Agencies' ||
              user?.user?.role === 'Agency Admin' ||
              user?.user?.role === 'Cashier' ||
              user?.user?.role === 'ABG Cashier' ||
              user?.user?.role === 'ABG Accounting' ||
              user?.user?.role === 'W32 Accounting' ||
              user?.user?.role === 'Accounting' ? (
              <MenuItem value={4}>TPV Fiserv</MenuItem>
            ) : null}
          </Select>
        </FormControl>
      </div>,
    );
    if (firstDate && secondDate)
      reactTable.current && reactTable.current.fireFetchData();
    else if (!firstDate && !secondDate)
      reactTable.current && reactTable.current.fireFetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, firstDate, secondDate, agenciesList]);

  useEffect(() => {
    if (Array.isArray(paymentsTPV.terminals)) {
      const cleanData = paymentsTPV.terminals.map((tpvPayment) => {
        return {
          id: tpvPayment.id,
          name: tpvPayment.applabel,
          reference: tpvPayment.reference,
          result: tpvPayment.result,
          amount: formatNumber(tpvPayment.amount),
          cardtype: tpvPayment.cardtype,
          authorizationTime: formatSingleDate(
            tpvPayment.authorizationTime,
          ),
          createdAt: formatSingleDate(tpvPayment.createdAt),
        };
      });
      setTPVPayments(cleanData);
    }
  }, [paymentsTPV]);
  useEffect(() => {
    if (paymentsTPVFiserv) {
      if (paymentsTPVFiserv.rows) {
        if (Array.isArray(paymentsTPVFiserv.rows)) {
          const cleanData = paymentsTPVFiserv.rows.map(
            (tpvFiservPayment) => {
              return {
                id: tpvFiservPayment.id,
                agencyName: tpvFiservPayment.agency.name,
                agencyId: tpvFiservPayment.agency.id,
                locationID: tpvFiservPayment.locationID,
                transactionTime: tpvFiservPayment.transactionTime,
                transactionDate: tpvFiservPayment.transactionDate
                  ? moment(tpvFiservPayment.transactionDate)
                    .utc()
                    .format('DD/MM/YYYY')
                  : '-',
                transactionAmount: formatNumber(
                  tpvFiservPayment.transactionAmount,
                ),
              };
            },
          );
          setTPVFiservPayments(cleanData);
        }
      }
    }
  }, [paymentsTPVFiserv]);

  const handleClearFilters = () => {
    setFirstDate(null);
    setSecondDate(null);
    setFiltered('');
    setCsvReport([]);
  };

  const handleChangeTab = (event) => {
    handleClearFilters();
    setTabSelected(event.target.value);
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };
  const handleAgencies = (agencies) => {
    setAgenciesList(agencies);
  };

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const handleTable = (row) => {
    return `/detail-payment/${row.original.karloID}`;
  };

  const handleExport = (data) => {
    const currentDate = moment().format('DD-MM-YYYY');
    const workbook = XLSX.utils.book_new();

    const wsDetalle = XLSX.utils.json_to_sheet(data?.Detalle);
    XLSX.utils.book_append_sheet(workbook, wsDetalle, "Detalle");

    const wsResumen = XLSX.utils.json_to_sheet(data?.Resumen);
    XLSX.utils.book_append_sheet(workbook, wsResumen, "Resumen");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Reporte de pagos ${tabSelected === 0 ? 'ODS' : 'ODP'}- ${currentDate}.xlsx`);
  };

  const handleExportComplete = (data) => {
    const currentDate = moment().format('DD-MM-YYYY');
    const workbook = XLSX.utils.book_new();

    const wsDetalle = XLSX.utils.json_to_sheet(data?.DetalleODS);
    XLSX.utils.book_append_sheet(workbook, wsDetalle, "Detalle ODS");

    const wsResumen = XLSX.utils.json_to_sheet(data?.ResumenODS);
    XLSX.utils.book_append_sheet(workbook, wsResumen, "Resumen ODS");

    const wsDetalleODP = XLSX.utils.json_to_sheet(data?.DetalleODP);
    XLSX.utils.book_append_sheet(workbook, wsDetalleODP, "Detalle ODP");

    const wsResumenODP = XLSX.utils.json_to_sheet(data?.ResumenODP);
    XLSX.utils.book_append_sheet(workbook, wsResumenODP, "Resumen ODP");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Reporte de pagos ODS y ODP - ${currentDate}.xlsx`);
  };

  useEffect(
    () => {
      if (Array.isArray(orders) && orders.length > 0) {
        const cleanPayments = orders
          .filter((payment) => payment && payment[order[tabSelected]])
          .map((payment) => {
            let toDeposit;
            let comissionWithOutIva;
            let iva;
            const agencyName = getNested(
              '-',
              payment[order[tabSelected]],
              'agency',
              'name',
            );

            const toogleZeroTaxes = getNested(
              false,
              payment[order[tabSelected]],
              'agency',
              'toogleZeroTaxes',
            );

            if (payment.billPocketID in paymentsHardcoded) {
              if (payment.totalToDepositAgency) {
                toDeposit = payment.totalToDepositAgency;
              } else {
                toDeposit =
                  paymentsHardcoded[payment.billPocketID].toDeposit;
              }
              if (payment.totalCommissionToAgency) {
                comissionWithOutIva = payment.totalCommissionToAgency;
              } else {
                comissionWithOutIva =
                  paymentsHardcoded[payment.billPocketID]
                    .comissionWithoutIVA;
              }
              if (payment.ivaComision && payment.ivaSurcharge) {
                iva = payment.ivaComision + payment.ivaSurcharge;
              } else {
                iva = paymentsHardcoded[payment.billPocketID].iva;
              }
            } else {
              if (payment.totalToDepositAgency) {
                toDeposit = payment.totalToDepositAgency;
              } else {
                toDeposit = payment.paymentMethod
                  ? payment.promotionWI
                    ? payment.amount - payment.surcharge
                    : payment.amount - payment.totalCommissionToAgency
                  : 0;
              }
              if (payment.totalCommissionToAgency) {
                comissionWithOutIva = payment.totalCommissionToAgency;
              } else {
                comissionWithOutIva = payment.paymentMethod
                  ? (payment.total - toDeposit) / 1.16
                  : 0;
              }

              if (payment.ivaComision && payment.ivaSurcharge) {
                iva = payment.ivaComision + payment.ivaSurcharge;
              } else {
                iva = payment.paymentMethod
                  ? comissionWithOutIva
                    ? (comissionWithOutIva * 0.16).toFixed(2)
                    : '-'
                  : 0;
              }
            }

            if (payment.totalToDepositAgency) {
              toDeposit = payment.totalToDepositAgency;
            } else if (payment.billPocketID && toogleZeroTaxes) {
              toDeposit = payment.total;
            }

            const clientFullName =
              payment[order[tabSelected]].nombre ||
                payment[order[tabSelected]].invoiceProfile
                ? payment[order[tabSelected]].nombre
                  ? payment[order[tabSelected]].nombre
                  : payment[order[tabSelected]].invoiceProfile
                    .fullName
                : '-';
            const orderID =
              payment[order[tabSelected]].reference ||
              payment[order[tabSelected]].orderID;
            const invoiceNumber = payment.invoiceNumber ? payment.invoiceNumber : payment.serviceOrder && payment.serviceOrder.invoiceNumber ? payment.serviceOrder.invoiceNumber : '-';  
            const total = payment ? formatNumber(payment.total) : '-';

            const date = moment(payment.date).format(
              'DD/MM/YYYY hh:mm a',
            );

            const billPocketID = payment.billPocketID || '-';
            const authNumber = payment.authNumber || '-';

            const transactionId =
              payment[order[tabSelected]].agency
                .paymentAggregators === 'fiserv'
                ? payment.authNumber
                : payment.billPocketID
                  ? payment.billPocketID
                  : '-';

            const paymentMethod = payment.type || '-';

            const clientAccount =
              payment[order[tabSelected]].clientAccount || '-';
            return {
              karloID: payment.id,
              karloServiceOrderID: payment[order[tabSelected]].id,
              agencyID: payment[order[tabSelected]].agencyID,
              agencyName,
              clientAccount,
              billPocketID,
              authNumber,
              transactionId,
              invoiceNumber,
              orderID,
              type: payment[order[tabSelected]].type,
              odsId: payment[order[tabSelected]].orderID,
              date,
              total,
              comission: comissionWithOutIva,
              iva,
              toDeposit: formatNumber(toDeposit),
              clientFullName,
              paymentMethod,
              months: payment.months,
              status: payment.status,
              origin: payment.origin,
            };
          });
        setPayments(cleanPayments);
      } else {
        setPayments([]);
      }
      props.setActionButton(
        <>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Inicial"
              format="DD/MM"
              className={classes.datePicker}
              value={firstDate}
              onChange={handleFirstDate}
              InputProps={{
                endAdornment: firstDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearFirstDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
            <span className={classes.range}>&nbsp;A&nbsp;</span>
            <DatePicker
              autoOk
              disableToolbar
              disableFuture
              variant="inline"
              label="Final"
              format="DD/MM"
              className={clsx(
                classes.datePicker,
                classes.datePickerRight,
              )}
              value={secondDate}
              onChange={handleSecondDate}
              InputProps={{
                endAdornment: secondDate ? (
                  <IconButton
                    aria-label="Select locale"
                    size="small"
                    onClick={handleClearSecondDate}
                  >
                    <Close />
                  </IconButton>
                ) : null,
              }}
            />
          </MuiPickersUtilsProvider>
          {role === 'Super Admin' ||
            role === 'Super Admin Agencies' ? (
            <Button
              variant="contained"
              onClick={() => setOpenModal(true)}
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              Seleccionar agencia(s)
            </Button>
          ) : null}
          <div className={classes.menuDesktop}>
            <Button
              startIcon={<GetApp style={{ color: 'primary' }} />}
              color="primary"
              size="large"
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              disabled={
                !firstDate || !secondDate || secondDate < firstDate
              }
              onClick={() => handleExport(dataToExport)}
            >
              Descargar
            </Button>

            {tabSelected !== 4 &&
              (user?.user?.role === 'Super Admin' ||
                user?.user?.role === 'Agency Admin') ? (
              <Button
                startIcon={<GetApp style={{ color: 'primary' }} />}
                color="primary"
                size="large"
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                disabled={
                  !firstDate || !secondDate || secondDate < firstDate
                }
                onClick={() => handleExportComplete(dataToExportComplete)}
              >
                Descargar ODS & ODP
              </Button>
            ) : null}
          </div>
          <div className={classes.menuMobile}>
            <MenuOption
              Button={
                <Button
                  variant="contained"
                  className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
                >
                  Ver opciones
                  <ArrowDropDownIcon className="kt-margin-l-10" />
                </Button>
              }
              options={[
                {
                  name: (
                    <Button
                      fullWidth
                      startIcon={<GetApp style={{ color: 'primary' }} />}
                      color="primary"
                      size="large"
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      disabled={
                        !firstDate || !secondDate || secondDate < firstDate
                      }
                      onClick={() => handleExport(dataToExport)}
                    >
                      Descargar
                    </Button>
                  ),
                  onClick: genericFuntion,
                },
                {
                  name: (
                    <Button
                      fullWidth
                      startIcon={<GetApp style={{ color: 'primary' }} />}
                      color="primary"
                      size="large"
                      variant="contained"
                      className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                      disabled={
                        !firstDate || !secondDate || secondDate < firstDate
                      }
                      onClick={() => handleExportComplete(dataToExportComplete)}
                    >
                      Descargar ODS & ODP
                    </Button>
                  ),
                  onClick: genericFuntion,
                },
              ]}
            />
          </div>
        </>,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tabSelected,
      orders,
      firstDate,
      secondDate,
      props.payments.paymentsForDownload,
      props.payments.paymentsCompleteForDownload,
      csvReportComplete,
      csvReport,
    ],
  );

  const handleFetchData = debounce(
    (state, instance) => {
      let init = firstDate ? firstDate : undefined;
      let end = secondDate ? secondDate : undefined;
      const { page, pageSize, sorted, filtered } = state;
      if (tabSelected !== 4) {
        getTPVPayments({ page, pageSize, sorted, filtered });
        paymentsToBring[tabSelected]({
          firstDate: init,
          secondDate: end,
          page,
          pageSize,
          sorted,
          filtered,
          tabSelected,
          userAgencyData,
          agenciesList,
        });
      }
      if (tabSelected === 4) {
        getTPVFiservPayments({
          page,
          pageSize,
          sorted,
          filtered,
          fromDate: init,
          toDate: end,
          agenciesList,
        });
      }
      setFiltered(filtered);
    },
    1000,
    { leading: false, trailing: true },
  );

  // ODS-ODP columns
  const columnsODPS = [
    {
      Header: () => <strong>Id de orden</strong>,
      accessor: 'orderID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Tipo</strong>,
      accessor: 'type',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && row.value === 'service' && 'Servicio'}
            {row.value && row.value === 'parts' && 'Refacciones'}
            {row.value && row.value === 'sales' && 'Ventas'}
            {row.value && row.value === 'fi' && 'F&I'}
            {row.value && row.value === 'pulled_apart' && 'Apartado'}
            {row.value && row.value === 'maintenance' && 'Mantenimiento'}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'date', 'Meses sin intereses'),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="sales">Ventas</option>
            <option value="service">Servicio</option>
            <option value="parts">Refacciones</option>
            <option value="fi">F&I</option>
            <option value="pulled_apart">Apartado</option>
          </select>
        );
      },
      show: tabSelected === 1,
    },
    {
      Header: () => <strong>ID de Cliente</strong>,
      accessor: 'clientAccount',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: tabSelected === 2,
    },
    {
      Header: () => <strong>No. Factura</strong>,
      accessor: 'invoiceNumber',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: tabSelected === 0,
    },
    {
      Header: () => <strong>ID Transacción</strong>,
      accessor: 'transactionId',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role !== 'Super Admin',
    },
    {
      Header: () => <strong>billPocket ID</strong>,
      accessor: 'billPocketID',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role === 'Super Admin',
    },
    {
      Header: () => <strong>Fiserv ID</strong>,
      accessor: 'authNumber',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: user?.user?.role === 'Super Admin',
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: superAdminRoles.includes(role),
    },
    {
      Header: () => <strong>Cliente</strong>,
      accessor: 'clientFullName',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'clientFullName', 'Cliente'),
    },
    {
      Header: () => <strong>Método de pago</strong>,
      accessor: 'paymentMethod',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({
              id: `PAYMENT.TYPE.${row.value}`,
            })}
          </div>
        </Link>
      ),
      width: getColumnWidth(
        payments,
        'paymentMethod',
        'Método de pago      ',
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="DEBIT VISA">DÉBITO VISA</option>
            <option value="DEBIT MASTERCARD">
              DÉBITO MASTERCARD
            </option>
            <option value="CREDIT AMERICAN-EXPRESS">
              CRÉDITO AMEX
            </option>
            <option value="CREDIT VISA">CRÉDITO VISA</option>
            <option value="CREDIT MASTERCARD">
              CRÉDITO MASTERCARD
            </option>
            <option value="OXXO">OXXO</option>
            <option value="SPEI">SPEI</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Meses</strong>,
      accessor: 'months',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? (
              <Badge className={classes.badges} variant="info">
                {`${row.value} meses`}
              </Badge>
            ) : (
              '-'
            )}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'date', 'Meses sin intereses'),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="3">3 meses</option>
            <option value="6">6 meses</option>
            <option value="9">9 meses</option>
            <option value="12">12 meses</option>
          </select>
        );
      },
    },
    {
      Header: () => <strong>Fecha de pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(payments, 'date', 'Fecha de pago'),
    },
    {
      Header: () => <strong>Monto</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'total', 'Total'),
    },
    {
      Header: () => <strong>Comisión</strong>,
      accessor: 'comission',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>IVA</strong>,
      accessor: 'iva',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {formatNumber(row.value)}
          </div>
        </Link>
      ),
      width: getColumnWidth(payments, 'iva', 'IVA'),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>A depositar</strong>,
      accessor: 'toDeposit',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
      show:
        superAdminRoles.includes(role) ||
        adminRoles.includes(role) ||
        accountingRoles.includes(role),
    },
    {
      Header: () => <strong>Estado</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && row.value === 'DONE' && 'Pagado'}
            {(row.value && row.value === 'REFUND' || row.value === 'CANCELED') && 'Devuelto'}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="DONE">Pagado</option>
            <option value="REFUND">Devuelto</option>
          </select>
        );
      },
      show: tabSelected !== 2,
    },
    {
      Header: () => <strong>Origen</strong>,
      accessor: 'origin',
      Cell: (row) => (
        <Link to={handleTable(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && row.value === 'online'
              ? 'En linea'
              : row.value &&
                (row.value === 'kiosko' || row.value === 'pos')
                ? 'Kiosko'
                : row.value.toUpperCase()}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            <option value="online">En linea</option>
            <option value="kiosko">Kiosko</option>
          </select>
        );
      },
    },
  ];

  const columns = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Etiqueta</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Referencia</strong>,
      accessor: 'reference',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Cantidad</strong>,
      accessor: 'amount',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Método de Pago</strong>,
      accessor: 'cardtype',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Resultado</strong>,
      accessor: 'result',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Autorización</strong>,
      accessor: 'authorizationTime',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Fecha de Creación</strong>,
      accessor: 'createdAt',
      Cell: (row) => (
        <Link to={`/karlouser-detail/${row.original.id}`}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
  ];

  const handleTableTPV = (row) => {
    return `/detail-payments-standalone/${row.original.id}/${row.original.agencyId}`;
  };

  const columnsTPVFiserv = [
    {
      Header: () => <strong>ID</strong>,
      accessor: 'id',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: true,
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: true,
    },
    {
      Header: () => <strong>Localización Id</strong>,
      accessor: 'locationID',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: true,
    },
    {
      Header: () => <strong>Tiempo de transacción</strong>,
      accessor: 'transactionTime',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: true,
    },
    {
      Header: () => <strong>Fecha de Transacción</strong>,
      accessor: 'transactionDate',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(
        tpvFiservPayments,
        'transactionDate',
        'Fecha de transacción',
      ),
      filterable: true,
    },
    {
      Header: () => <strong>Total de transacción</strong>,
      accessor: 'transactionAmount',
      Cell: (row) => (
        <Link to={handleTableTPV(row)}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: true,
    },
  ];

  const modalAgencies = () => {
    return (
      <Modal
        fullWidth={true}
        maxWidth="xs"
        open={openModal}
        onClick={() => setOpenModal(false)}
        closeModal={() => setOpenModal(false)}
        actionButtonText={'Cerrar'}
        type={'agencies'}
        dialogTitle={'Seleccionar agencia(s)'}
        dialogText={'Seleccione las agencias que deseas visualizar.'}
        dialogChildren={
          <div className={classes.multiselect}>
            <MUIAutocompleteMultipleAgencies
              // prettier-ignore
              newAgency={(agency) => setAgency(agency)}
              agenciesList={(agencies) => handleAgencies(agencies)}
              agenciesNames={(agencies) => setAgenciesName(agencies)}
              dashboarAgency={user?.user?.agencyID}
              userInfo={userInfo}
            />
          </div>
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      {modalAgencies()}
      <Paper>
        {tabSelected == '3' ? (
          <ReactTable
            ref={reactTable}
            NoDataComponent={() => (
              <div className="rt-noData">Sin resultados</div>
            )}
            manual
            onFetchData={handleFetchData}
            data={tpvPayments}
            pages={paymentsTPV.tablePages}
            columns={columns}
            // filterable
            className="-striped -highlight"
            previousText="Anterior"
            nextText="Siguiente"
            loading={paymentsTPV.isLoading}
            loadingText="Cargando datos..."
          />
        ) : tabSelected == '4' ? (
          <ReactTable
            ref={reactTable}
            NoDataComponent={() => (
              <div className="rt-noData">Sin resultados</div>
            )}
            manual
            onFetchData={handleFetchData}
            data={tpvFiservPayments}
            pages={
              paymentsTPVFiserv ? paymentsTPVFiserv.tablePages : 1
            }
            columns={columnsTPVFiserv}
            // filterable
            className="-striped -highlight"
            previousText="Anterior"
            nextText="Siguiente"
            loading={
              paymentsTPVFiserv ? paymentsTPVFiserv.tablePages : false
            }
            loadingText="Cargando datos..."
          />
        ) : (
          <ReactTable
            ref={reactTable}
            NoDataComponent={() => (
              <div className="rt-noData">Sin resultados</div>
            )}
            manual
            onFetchData={handleFetchData}
            data={payments}
            pages={props.payments.tablePages}
            columns={columnsODPS}
            filterable
            className="-striped -highlight"
            previousText="Anterior"
            nextText="Siguiente"
            loading={props.payments.isLoading}
            loadingText="Cargando datos..."
          />
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  payments: state.payments,
  paymentsTPV: state.payments.paymentsTPVfromdb,
  paymentsTPVFiserv: state.payments.paymentsTPVFiservfromdb,
  // paymentsTPV: makeSelectPaymentsTPV(state),
  role: makeSelectUserRoleFromState(state),
  user: selectAuth(state),
  agencies: selectAgencies(state),
  userAgency: makeSelectGetOneAgency(state),
});

const mapDispatchToProps = {
  getPayments: getPayments,
  getPaymentOrders: getPaymentsOrders,
  getTPVPayments: getTPVPayments,
  getTPVFiservPayments: getTPVFiservPayments,
  getPaymentsForDownload: getPaymentsForDownload,
  getPaymentsTPVForDownload: getPaymentsTPVForDownload,
  getPaymentsCompleteForDownload: getPaymentsCompleteForDownload,
  getOneAgency: getOneAgency,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PaymentsTable),
);
